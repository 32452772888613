import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate(); // Hook to programmatically navigate

    // Function to handle sign out
    const handleSignOut = () => {
        // Remove the adminToken from local storage
        localStorage.removeItem('adminToken');
        // Redirect to the home page or login page
        navigate('/'); // Adjust the route as necessary
    };

    // Check if the user is signed in
    const isSignedIn = !!localStorage.getItem('adminToken');

    return (
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand>Admin Portal</Navbar.Brand>
            <Nav className="mr-auto">
                <Nav.Link as={Link} to="/api-keys">Manage API Key</Nav.Link>
                <Nav.Link as={Link} to="/model-config">Manage Model Config</Nav.Link>
                <Nav.Link as={Link} to="/manage-model">Manage Model</Nav.Link>
                {/* <Nav.Link as={Link} to="/manage-route">Manage Routing</Nav.Link> */}
                <Nav.Link as={Link} to="/manage-pipeline">Manage Pipeline</Nav.Link>
                <Nav.Link as={Link} to="/change-password">Change Password</Nav.Link>
            </Nav>
            {isSignedIn && (
                <Nav className="ml-auto">
                    <Button variant="outline-light" onClick={handleSignOut}>Sign Out</Button>
                </Nav>
            )}
        </Navbar>
    );
};

export default Header;
