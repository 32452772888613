import React, { useState, useEffect } from 'react';
import { getAllPipelines, deletePipeline, getJobDetails } from '../services/api';
import ReactJson from 'react-json-view';

const PipelineCleanupPage = () => {
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPipelineDetails, setSelectedPipelineDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const adminId = localStorage.getItem('adminId');
  const token = localStorage.getItem('adminToken');

  useEffect(() => {
    fetchAllPipelines();
  }, []);

  const fetchAllPipelines = async () => {
    try {
      setLoading(true);
      const response = await getAllPipelines(adminId, token);
      if (response.data.success) {
        setPipelines(response.data.data);
      } else {
        console.error('Failed to fetch pipelines');
      }
    } catch (error) {
      console.error('Error fetching pipelines:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePipeline = async (pipelineId, status, username) => {
    if (status !== 'active') {
      const confirmation = window.confirm(
        `This Pipeline has already been Soft Deleted By User ${username}. Do you want to delete it permanently?`
      );
      if (!confirmation) return;
    }
    const confirmation = window.confirm('Are you sure you want to delete this pipeline?');
    if (!confirmation) return;

    try {
      const response = await deletePipeline(pipelineId, token);
      if (response.data.success) {
        alert('Pipeline deleted successfully!');
        fetchAllPipelines(); // Refresh the pipelines list
      } else {
        alert('Failed to delete the pipeline.');
      }
    } catch (error) {
      console.error('Error deleting pipeline:', error);
      alert('Error occurred while deleting the pipeline.');
    }
  };

  const handleShowDetails = async (pipelineId) => {
    try {
      const user = {
        user_id: adminId,
        user_name: 'admin',
        user_email: '',
        api_key: '',
        user_role: ['admin'],
      };
      const response = await getJobDetails(
        {
          pipeline_id: pipelineId,
          user_detail: user,
        },
        token
      );
      if (response.success) {
        setSelectedPipelineDetails(response.data);
        setIsModalOpen(true);
      } else {
        alert('Failed to fetch pipeline details.');
      }
    } catch (error) {
      console.error('Error fetching pipeline details:', error);
      alert('Error occurred while fetching pipeline details.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPipelineDetails(null);
  };

  const handleCopyPayload = () => {
    navigator.clipboard.writeText(JSON.stringify(selectedPipelineDetails, null, 2))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copy state after 2 seconds
      })
      .catch(() => alert("Failed to copy payload"));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="unique-pipeline-cleanup-container">
      <h2 className="unique-page-title">Pipeline Cleanup</h2>

      {pipelines.length === 0 ? (
        <p>No pipelines found to clean up.</p>
      ) : (
        <div className="unique-pipeline-card-container">
          {pipelines.map((pipeline) => (
            <div className="unique-pipeline-card" key={pipeline.pipeline_id}>
              <h3 className="unique-pipeline-name">{pipeline.pipeline_name}</h3>
              <p className="unique-pipeline-details">Pipeline ID: {pipeline.pipeline_id}</p>
              <p className="unique-pipeline-details">User: {pipeline.user_name}</p>
              <p className="unique-pipeline-details">Status: {pipeline.status}</p>
              <p className="unique-pipeline-details">
                Deleted Date: {pipeline.deleted_date ? pipeline.deleted_date : 'N/A'}
              </p>
              <div className="unique-pipeline-buttons">
                <button
                  className="unique-action-button unique-delete-button"
                  onClick={() =>
                    handleDeletePipeline(pipeline.pipeline_id, pipeline.status, pipeline.user_name)
                  }
                >
                  Delete Pipeline
                </button>
                <button
                  className="unique-action-button unique-details-button"
                  onClick={() => handleShowDetails(pipeline.pipeline_id)}
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>×</button>
            <h3 className="modal-title">Pipeline Details</h3>
            {selectedPipelineDetails && selectedPipelineDetails.length > 0 ? (
              <div className="modal-details">
                {selectedPipelineDetails.map((detail, index) => (
                  <div key={index} className="detail-item">
                    <p><strong>Job ID:</strong> {detail.job_id}</p>
                    <p><strong>Job Type:</strong> {detail.job_type}</p>
                    <p><strong>Job Function:</strong> {detail.job_func}</p>
                    <p><strong>Created At:</strong> {detail.created}</p>
                    <p><strong>BigData:</strong> {detail.big_data ? 'Yes' : 'No'}</p>

                    {/* Display JSON payload using react-json-view */}
                    <div className="modal-json-payload">
                      <ReactJson src={JSON.parse(detail.payload)} theme="monokai" collapsed={2} />
                    </div>

                    {/* Copy Payload Button */}
                    <button className="copy-btn" onClick={handleCopyPayload}>
                      {copied ? "Copied!" : "Copy Payload"}
                    </button>

                    <hr />
                  </div>
                ))}
              </div>
            ) : (
              <p>Loading details...</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PipelineCleanupPage;
